import { flexRender, Header } from "@tanstack/react-table";

export function TableHeaderContent<Data extends Record<string, any>>(props: Header<Data, unknown>) {
    return (
        <div className="d-flex align-middle gap-1 flex-grow-1">
            {props.isPlaceholder
                ? null
                : flexRender(props.column.columnDef.header, props.getContext())}
        </div>
    );
}
